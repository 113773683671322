import { useEffect, useState } from 'react';
import React from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { createContext, useCallback } from 'react';

import helpers from './helpers';


const { forwardRef, useRef, useImperativeHandle } = React;

const MarketDataGrid = React.forwardRef ((props, ref) => {

  const gridRef = React.useRef();
  const current_business_date = React.useRef();
  const curveTenors = React.useRef();

  const [businessDate, setBusinessDate] = useState([0]);
  const [colDefs, setColumnDefs] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [marketDataArray, setMarketDataArray] = useState([]);

  const Market_data_change = (event) =>
  {
    current_business_date.current = event.data.business_date;
    var price_request_out = {};
    var business_date_rates = {};

    price_request_out["business_date"] = current_business_date.current;

    var updated_rates = event.data

    Object.keys(updated_rates).forEach(key => {
      console.log("Key : " + key);
      if ( curveTenors.current.includes(key) )
      {
        business_date_rates[key] = event.data[key]/100.0;
      }
    });

    price_request_out["market_data"] = business_date_rates;
    props.marketDataCallback(price_request_out);
  }

  useEffect(() => {

    if ( gridRef.current.api != undefined)
      gridRef.current.api.setColumnDefs(colDefs);
  }, [colDefs]);

  useEffect(() =>
  {
      if ( props !== undefined && props.rowData !== undefined  && props.rowData.tenors !== undefined )
      {
          const keys = Object.values(props.rowData.tenors);
          const tmpColDefs = [{field : "business_date_js", headerName: "Date",  pinned: 'left', width: '100px',
            filter: 'agDateColumnFilter',sortable: true,flex: 1,
            valueFormatter: function (params) { return helpers.date_js_to_date_mm_dd_yyyy(params.data.business_date_js); },
            resizable: true,
          }];

        keys.forEach(key => { tmpColDefs.push({field : key['Term'], flex: 2, editable: true }) });
        setColumnDefs(tmpColDefs);

        var set_curve_tenor = []

        curveTenors.current = []

        Object.values(props.rowData.tenors).forEach((item, i) => {
          curveTenors.current.push(item['Term'])
        });

        setMarketData(props.rowData.market_data);
      }
  }, [props]);

  useEffect(() => {

    if ( marketData!== undefined && marketData.length > 0 )
    {
      var max_business_date = 0;

      var defaultSortModel = [
        { colId: 'business_date', sort: 'desc', sortIndex: 0 }
      ];
      gridRef.current.columnApi.applyColumnState({ state: defaultSortModel });

      var market_data_dict = {}
      var market_data_array = []

      marketData.forEach(market_data_entry_in => {

        var market_data_entry_dated = JSON.parse(JSON.stringify(market_data_entry_in));
        market_data_entry_dated['business_date_js'] = helpers.int_to_date_js(market_data_entry_dated['business_date']);
        market_data_array.push(market_data_entry_dated);

        var market_data_entry = JSON.parse(JSON.stringify(market_data_entry_in));

        var business_date = market_data_entry['business_date'];
        delete market_data_entry['business_date'];

        if ( business_date > max_business_date )
          max_business_date = business_date;

        market_data_dict[business_date] = market_data_entry;
      });

      props.setMarketDataDict(market_data_dict)
      setMarketDataArray(market_data_array)
    }


  }, [marketData]);

  useEffect(() => {

    if ( marketDataArray !== undefined &&   gridRef.current.api !== undefined )
    {
      gridRef.current.api.forEachNode(function(node) {

          if ( node.rowIndex == 0 )
          {
            node.setSelected(true);
            Market_data_change(node)
          }
        }
      );
    }
  }, [marketDataArray]);

const gridOptions = {
    // PROPERTIES
    // Objects like myRowData and myColDefs would be created in your application
    //rowData: rowData.tenors,
    //columnDefs: [  {headerName: 'Term', field: 'Term'}],
    //pagination: true,
    rowSelection: 'single',

    // EVENTS
    // Add event handlers
    onRowClicked: event => {
      console.log('row', event);
    },

    onGridReady: event =>
    {
      console.log('Grid Ready', event);
    },

    onCellClicked: event => {

      if ( current_business_date.current === event.data.business_date )
      {
        props.tenorScrollCallback(event.colDef.field);
      } else {
        Market_data_change(event)
      }

    },

    onColumnResized: event => console.log('A column was resized'),

    // CALLBACKS
    getRowHeight: (params) => 25
  }

  const onCellEditingStopped = useCallback((updated_price) =>
  {
    Market_data_change(updated_price)
  }, []);

   return (
       <div className="ag-theme-balham-dark" style={{height:"30vh", width: "100%"}}>
           <AgGridReact rowData={marketDataArray} columnDefs={colDefs} gridOptions={gridOptions} ref={gridRef} onCellEditingStopped={onCellEditingStopped}>
           </AgGridReact>
       </div>
     )
  });

export default MarketDataGrid;
