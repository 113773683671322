import logo from './logo.svg';
import { useEffect, useState } from 'react';
import LineChart from './components/MyChart';
import BondPriceChart from './components/BondPriceChart';
import BondGrid from './components/BondGrid';
import PositionsPanel from './components/PositionsPanel';
import EconomicDataPanel from './components/EconomicDataPanel';
import MarketDataGrid from './components/MarketDataGrid';
import CalculatorPanel from './components/CalculatorPanel';
import ChartedKeyRates from './components/ChartedKeyRates';
import React from 'react';
import DatePicker from "react-datepicker";
import InfAndUnpPanel from './components/InfAndUnpPanel'

import 'react-datepicker/dist/react-datepicker.css';

/*import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';*/

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { TailSpin } from  'react-loader-spinner'

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import { Container, Row, Col } from 'react-bootstrap/';

import { PRICER_URL} from './helpers/Constants'

import { GoogleLogin, GoogleOAuthenProvider, useGoogleLogin} from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

import SessionHelper from './helpers/SessionHelper'

import { jwtDecode } from "jwt-decode";

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';

import './App.css';

function App() {

  const [rowData, setRowData] = useState(undefined);
  const [instrumentData, setInstrumentData] = useState(undefined);
  const [instrumentDataPerCUSIP, setInstrumentDataPerCUSIP] = useState(undefined);
  const [bondTemplate, setBondTemplate] = useState(undefined);
  const [pricedBonds, setPricedBonds] = useState(undefined);
  const [pricingData, setPricingData] = useState([]);
  const [pricingToken, setPricingToken] = useState([]);
  const [pricingResults, setPricingResults] = useState([]);
  const [chartPricingResults, setChartPricingResults] = useState([]);
  const [pricingPerCusip, setPricingPerCusip] = useState();
  const [marketData, setMarketData] = useState();
  const [tenor, setTenor] = useState();
  const [bond, setBond] = useState();
  const [panel, setPanel] = useState();
  const [showLoading, setShowLoading] = useState([false]);
  const [pricingBond, setPricingBond] = useState([false]);
  const [valuationDate, setValuationDate] = useState(new Date());
  const [bondPricingError, setBondPricingError] = useState();
  const [fedStaticData, setFedStaticData] = useState();
  const [bondChartData, setBondChartData] = useState();

  const [userCredentials, setUserCredentials] = useState();
  const [positions, setPositions] = useState();
  const [exposure, setExposure] = useState();
  const [logonResponse, setLogonResponse] = useState();
  const [tradeBooked, setTradeBooked] = useState();
  const [marketDataTab, setMarketDataTab] = useState();
  const [histMarketData, setHistMarketData] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [histData, setHistData] = useState();
  const [marketDataDict, setMarketDataDict] = useState()
  const [histBusinessDate, setHistBusinessDate] = useState()


  const marketDataRef = React.useRef();
  const currentKeyRates = React.useRef();
  const marketDataTenors = React.useRef();
  const currentValuationDate = React.useRef();
  const calculatorReplyCounter = React.useRef();
  const currentBondPrices = React.useRef();

  const reprice = () =>
  {
    if ( rowData != undefined && rowData != undefined )
    {
      var instrument_group = [{},{},{},{}]

      var instrument_count = Object.keys(rowData.ref_data).length
      for (const [key, value] of Object.entries(rowData.ref_data)) {
        var calculator_id = instrument_count%4
        instrument_group[calculator_id][key] = value
        instrument_count = instrument_count + 1
      }

      calculatorReplyCounter.current = 0
      currentBondPrices.current = {}
      instrument_group.forEach((item, i) => {
        repriceInstrumentGroup(item)
      });
    }
  }

  const repriceInstrumentGroup = (instrumentGroup) =>
  {
    var price_request_out = {};
    var curve = {};

    setShowLoading(true);

    if ( marketData === undefined || rowData == undefined || marketData.length === 0 || rowData.length === 0 )
      return;

    currentKeyRates.current = marketData;
    marketDataTenors.current = rowData.tenors;
    currentValuationDate.current = valuationDate;

    curve["curve_points"] = marketData["market_data"];
    curve["bond_template"] = rowData.bond_template;

    price_request_out["business_date"] = new Date(valuationDate).toISOString().slice(0, 10)
    price_request_out["portal"] = "BOND_PORTAL"
    price_request_out["operation"] = "CURVE_PRICE"
    price_request_out["curve"] = curve
    price_request_out["curve"]["curve_date"] = marketData["business_date"]
    price_request_out["curve"]["curve_out"] = rowData.curve_out
    price_request_out["instruments"] = instrumentGroup

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(price_request_out)
    };

    fetch(PRICER_URL + '/submit_request/', requestOptions)
      .then(res => res.json())
      .then(result => setPricingToken(result));
  }


  useEffect(() => {

      reprice();

  }, [marketData]);


  useEffect(() => {

      if (marketDataDict !== undefined && histBusinessDate !== undefined )
      {
        var hist_market_data = marketDataDict[histBusinessDate];

        if (hist_market_data !== undefined) {

          var pricing_market_data = {}
          pricing_market_data['business_date'] = histBusinessDate;
          pricing_market_data['market_data'] = {};

          for (const [curve_index, rate] of Object.entries(hist_market_data)) {
              console.log(curve_index + " - "+ rate)
              pricing_market_data['market_data'][curve_index] = rate/100.0;
          }

          console.log(pricing_market_data);
          Market_data_change(pricing_market_data);
        } else {
          console.log('Market Data not found for ' + histBusinessDate)
        }
      }

  }, [marketDataDict, histBusinessDate]);


  const checkVisible = (elm) =>
  {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  const Market_data_change = (value) =>
  {
    setShowLoading(true);
    setMarketData(value);
  }


  const DataExport = ( gridRef ) =>
  {
     var settlment_date_iso = new Date(currentValuationDate.current).toISOString().slice(0, 10).replace(/-/g, '');;
      var additional_data_array = [
        [],
        [
          {
            data: {
              value: 'Valuation Date',
              type: 'String',
            },
          },
          {
            data: {
              value: settlment_date_iso,
              type: 'String',
            },
          },
        ],
        [],
        [
          {
            data: {
              value: 'Key Rates',
              type: 'String',
            },
          },
          {
            data: {
              value: currentKeyRates.current["business_date"],
              type: 'String',
            },
          },
        ],
      ];


      const keys = Object.values(marketDataTenors.current);

      marketDataTenors.current.forEach((item, i) => {
        console.log(item['Term']);

        var key_rate_entry = [];
        var tenor_out = {};
        tenor_out['data'] = {};
        tenor_out['data']['value'] = item['Term'];
        tenor_out['data']['type'] = 'String';

        key_rate_entry.push(tenor_out);

        var rate_out = {};
        rate_out['data'] = {};
        var rate =  currentKeyRates.current["market_data"][item['Term']];
        rate = rate * 100.0;
        rate_out['data']['value'] = rate.toFixed(3);
        rate_out['data']['type'] = 'Float';
        key_rate_entry.push(rate_out);

        additional_data_array.push(key_rate_entry);

      });

      var space = [];
      space['data'] = {};
      space['data']['value'] = '';
      space['data']['type'] = 'String';
      additional_data_array.push(space);

      var file_name = "ustreasuries.online." + settlment_date_iso + ".csv";

       var params = {
         prependContent: additional_data_array,
         fileName:file_name,
       };

       gridRef.current.api.exportDataAsCsv(params);
  }

  useEffect(() => {

      if ( pricingToken.request_id === undefined )
        return;

      if ( pricingToken['static_data'] !== undefined )
        setFedStaticData( pricingToken['static_data'] );

      if (pricingToken['bond_chart_data'] !== undefined )
      {
        setBondChartData( pricingToken['bond_chart_data'] );
      }

      setTimeout(() => {

        var check_results = {};
        check_results["request_id"] = pricingToken.request_id;
        check_results["url"] = pricingToken.url;
        const requestOptionsResults = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(check_results) };
        fetch(PRICER_URL + '/check_request/', requestOptionsResults) .then(res => res.json())
        .then(result => setPricingResults(result)
      );


    }, 200);


  }, [pricingToken]);

  useEffect(() =>
  {

    var todayDate = new Date(valuationDate).toISOString().slice(0, 10);
    reprice();

  }, [valuationDate]);

  useEffect(() =>
  {

    if (pricingResults.state == 1 )
    {
      var pricer_re_request = {};
      pricer_re_request["request_id"] = pricingResults.request_id;
      pricer_re_request["url"] = pricingResults.url;
      setPricingToken(pricer_re_request);
      return;
    }

    if ( pricingResults.operation == "CURVE_PRICE")
    {
      calculatorReplyCounter.current = calculatorReplyCounter.current + 1

      setPricedBonds(pricingResults.priced_instruments)

      for (const [key, value] of Object.entries(pricingResults.priced_instruments)) {
        currentBondPrices.current[key] = value
      }

      if ( calculatorReplyCounter.current == 4 )
      {
        setShowLoading(false)
        setPricingBond(false)
        setPricedBonds(currentBondPrices.current)
        calculatorReplyCounter.current = 0
        setChartPricingResults(pricingResults)
      } else if ( calculatorReplyCounter.current == 1 )
      {
        setChartPricingResults(pricingResults)
      }

    } else if ( pricingResults.operation == "PRICE_TO_YIELD" || pricingResults.operation == "YIELD_TO_PRICE" || pricingResults.operation == "PRICER_SETUP" )
    {
      var bond_pricing_results = pricingResults.bond;

      if ( bond_pricing_results != undefined )
      {
        if ( bond_pricing_results.error_code != undefined && bond_pricing_results.error_code == "0" )
        {

          bond_pricing_results['dv01']=bond_pricing_results['DV01']/100;
          bond_pricing_results['DirtyPrice']=bond_pricing_results['DirtyPrice']/1000000000;
          bond_pricing_results['AI']=bond_pricing_results['AI']/1000000000;;
          bond_pricing_results['AI(Days)']=bond_pricing_results['AI(Days)'];
          bond_pricing_results['ModifiedDuration']=bond_pricing_results['modified_duration']/100;
          bond_pricing_results['Convexity']=bond_pricing_results['convexity']/100;
          bond_pricing_results['zSpread']=bond_pricing_results['zSpread']/100;

          setBond(bond_pricing_results);

          var element = document.getElementById("support");

          if ( !checkVisible(element) )
            window.scrollTo(0, document.getElementById('support').offsetTop);
        }
        else
        {
          setBondPricingError(bond_pricing_results.error);
        }

        setShowLoading(false);
        setPricingBond(false);
      }

      console.log(bond);
    }

  }, [pricingResults]);


  const requestOptionsData = {
  };

  useEffect(() => {

    if ( bond === undefined )
      return;

    var element = document.getElementById("calculator");

    if ( !checkVisible(element) )
      window.scrollTo(0, document.getElementById('calculator').offsetTop);

  }, [bond]);

  useEffect(() => {
    setShowLoading(true);

    fetch(PRICER_URL + '/data',requestOptionsData)
      .then(res => res.json())
      .then(result => setRowData(result.data))
  }, []);

  useEffect(() => {

    if (rowData !== undefined && rowData.instrument_data !== undefined && rowData.bond_template !== undefined )
    {

      fetch(PRICER_URL + '/get_hist_data',requestOptionsData)
        .then(res => res.json())
        .then(result => setHistData(result.data))

      setInstrumentData(rowData.instrument_data);
      setBondTemplate(rowData.bond_template);
      setHistMarketData(rowData.market_data);

      var instruments_per_cusip = {}

      rowData.instrument_data.forEach((instrument, i) => {
        instruments_per_cusip[instrument['cusip']] = instrument
      });

      setInstrumentDataPerCUSIP(instruments_per_cusip)
    }

  }, [rowData]);

  const HandleSelect = (key) => {
    setSelectedIndex(key);
  }

  useEffect(() => {

    if (userCredentials !== undefined )
    {
      console.log(userCredentials);

      SessionHelper.submit_login(userCredentials, (logon_response) => { setLogonResponse(logon_response['data']) });
      setPositions(undefined);
      setExposure(undefined);
    }

  }, [userCredentials]);

  useEffect(() => {

     if ( logonResponse != undefined )
     {
         var position_request = {}
         position_request = {}
         position_request['email'] = logonResponse['email']
         position_request['jti'] = logonResponse['jti']

         SessionHelper.get_positions(position_request, (positions_in) =>
           {
             if ( positions_in !== undefined && positions_in.positions !== undefined )
             {
               var positions_out = {}
               positions_in.positions.forEach((position, i) => {
                 positions_out[position['symbol']] = position;
               });

               setPositions(positions_out);
             }
           });
         };

  }, [logonResponse, tradeBooked]);

  const responseMessage = (response) => {

    const USER_CREDENTIAL = jwtDecode(response.credential);
    setUserCredentials(USER_CREDENTIAL);

  };

  const errorMessage = (error) => { console.log(error); };

  const logged_in_div =
  <div style={{textAlign:'left', paddingTop:'5px'}}>
    <Row>
      <Col>
        {userCredentials == undefined ? null : userCredentials.name}
      </Col>
    </Row>
    <Row>
      <Col style={{fontSize:'10px'}}>
        {userCredentials == undefined ? null : userCredentials.email}
      </Col>
    </Row>
  </div>

  return (
    <div>
      <nav>
      <div>
        <div>
        <div style={{color:'white', fontWeight:'700',textAlign:'left', fontSize:'20px', opacity:'0.8',  whiteSpace: 'nowrap'}}>
          US Treasuries
        </div>
        <div style={{float:'left', marginRight:'10px'}}>
          <a style={{float:'left',fontSize:14, textDecoration:'none'}} href="https://ustreasuries.online/how_to.html">Tutorial</a>
        </div> {/*
                    <div style={{float:'left', marginRight:'10px'}}>
                      <a style={{float:'left',fontSize:14, textDecoration:'none'}} href="https://hist.ustreasuries.online/superset/dashboard/p/V2p1zdJvjwd/">History</a>
                    </div>
                    <div style={{float:'left',marginRight:'10px', marginTop:'-1px'}}>
                      <a style={{fontSize:14, textDecoration:'none'}} href="https://swap.ustreasuries.online">Swaps</a>
                    </div>
                    <div style={{float:'left',marginRight:'10px', marginTop:'-1px'}}>
                      <a style={{fontSize:14, textDecoration:'none'}} href="https://options.ustreasuries.online">Options</a>
                    </div>
                  */}
              </div>
                  </div>
                  <div style={{marginLeft: "auto"}}>
              <Row>
              <Col>
              <div className="ag-theme-balham-dark" style={{width:'300px'}}>
                {
                  showLoading == true ?
                    <div style={{justifyContent:'right', paddingLeft:250}}><TailSpin height="35" width="35" color='#61dafb' ariaLabel='pricing'/></div>
                    : <div style={{marginLeft: "auto",marginTop:'-10px', whiteSpace: 'nowrap'}}>
                        <div className="datepicker__title">
                            <div className="datepicker__title"> Valuation Date </div>
                            <div className="datepicker__title">
                            <DatePicker selected={valuationDate} onChange={(date:Date) => setValuationDate(date)} />
                          </div>
                          </div>
                      </div>
                }
              </div>
              </Col>
              <Col>
              <div>
              <Row style={{opacity:'80%'}}>
<Col className="nav--logo_text">
  <div style={{fontSize:'14px',  textAlign: 'right', textWrap: 'nowrap'}}>
  {
    userCredentials == undefined ? <div style={{paddingTop:'10px'}}>Sign-in with</div> : logged_in_div
  }
  </div>
</Col>
<Col>
<GoogleOAuthProvider clientId="66097256498-ifgfagl8lb2n2sv95n47d9r19qatjipc.apps.googleusercontent.com">
  <div style={{margin:'0px', paddingTop:'5px'}}>
    <GoogleLogin onSuccess={responseMessage} onError={errorMessage} theme='filled_black' size='medium' type='icon'/>
  </div>
</GoogleOAuthProvider>
</Col>
</Row>
              </div>
              </Col>
              </Row>
              </div>
        </nav>

        <div style={ ( showLoading == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
        <Container fluid>
            <Row  style={{display: 'flex', justifyContent: 'center', width:'100%', paddingTop:'5px'}}>
              <Col  style={ ( showLoading == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}} >
              <Tabs style={{textDecoration: 'none'}}
              activeKey={marketDataTab} onSelect={(active_panel) => setMarketDataTab(active_panel)} className="mb-3">
                  <Tab eventKey="key_rates" title="Rates">
                    <Row>
                    <Col style={{width:'45%', justifyContent: 'right', paddingRight:'10px'}}>
                    <MarketDataGrid rowData={rowData}
                    marketDataCallback={Market_data_change}
                      tenorScrollCallback= {(tenor)=> {setTenor(tenor);}}
                      setMarketDataDict= {(marketDataDictIn)=> {setMarketDataDict(marketDataDictIn);}}
                      ref={marketDataRef} />
                      <div className="ag-theme-balham-dark">
                        <div style={{paddingTop:'5px',color:'#ffeed9'}}>
                          (Select the row to reprice the curve and the bonds below)
                        </div>
                      </div>
                    </Col>
                    <Col style={{width:'45%', justifyContent: 'right', paddingRight:'10px'}}>
                      <div style={ ( showLoading == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                      <LineChart pricingResults={chartPricingResults} tenorScrollCallback= {(tenor)=> {setTenor(tenor);}}/>
                      </div>
                    </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="key_rates_chart" title="Spreads">
                    <Col style={{justifyContent: 'right', paddingRight:'10px'}}>
                      <div style={ ( showLoading == true ) ? {pointerEvents: "none", opacity: "0.4" } : {heighy:'60vh'}}>
                      <ChartedKeyRates
                        histMarketData={histData}
                        on_hist_business_date_change={(hist_business_date) => {setHistBusinessDate(hist_business_date)}}
                        options={{ maintainAspectRatio: false, aspectRatio: 1 }}/>
                      </div>
                    </Col>
                  </Tab>
                  <Tab eventKey="cpi" title="Inflation">
                    <Col style={{justifyContent: 'right', paddingRight:'10px'}}>
                      <div style={ ( showLoading == true ) ? {pointerEvents: "none", opacity: "0.4" } : {height:'50vh'}}>
                      <InfAndUnpPanel
                        histMarketData={histData}
                        on_hist_business_date_change={(hist_business_date) => {setHistBusinessDate(hist_business_date)}}
                        />
                      </div>
                    </Col>
                  </Tab>
                  </Tabs>
                </Col>
              </Row>
        </Container>



        {
        <Container fluid>
            <Row  style={{display: 'flex', justifyContent: 'center', width:'100%', paddingTop:'15px'}}>
              <Col>
              <Tabs id="controlled-tab-example" activeKey={panel} onSelect={(active_panel) => setPanel(active_panel)} className="mb-3">
                <Tab eventKey="bonds" title="Bonds">
                  <div style={ ( showLoading == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}} id='bond_blotter'>
                    <div style={ ( pricingBond == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                      <div className="App-Row">
                        <div className="App-Bonds">
                          <BondGrid instrumentData={instrumentData} marketData={marketData}
                            pricedBonds={pricedBonds}
                            dataExportCallback={DataExport} tenor={tenor}
                              bondSelectorCallback={(bond)=>{bond["operation"] = "PRICER_SETUP";setBond(bond);}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="positions" title="Positions" disabled={userCredentials == undefined ? true : false }>
                  <PositionsPanel userCredentials={userCredentials} positions={positions} selectedBond={bond}
                    pricedBonds={pricedBonds} instrumentDataPerCUSIP={instrumentDataPerCUSIP}
                    bondSelectorCallback={(bond)=>{bond["operation"] = "PRICER_SETUP";setBond(bond);}}/>
                </Tab>
                <Tab eventKey="economic_data" title="Fed News">
                  <EconomicDataPanel url={PRICER_URL}/>
                </Tab>
              </Tabs>
            </Col>
            </Row>
          </Container>
        }


      <div style={{margin:'20px'}} id="calculator">
        <Container fluid>
          <Row>
            <Col>
              <div style={ ( showLoading == true || bond === undefined ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
              <div style={ ( pricingBond == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
              <hr style={{ margin: '10px', background: 'white', height: 1}}/>
              <div style={{marginLeft:'15px'}}>
                {bond !== undefined ? bond.name : ""}
              </div>
              <div className="ag-theme-balham-dark">
                <CalculatorPanel bond={bond}
                                bondPricingError={bondPricingError}
                                marketData={marketData}
                                valuationDate={valuationDate}
                                bondTemplate={bondTemplate}
                                fedStaticData={fedStaticData}
                                bondChartData={bondChartData}
                                userCredentials={userCredentials}
                                positions={positions}
                                tradeBookCallback={(response)=>{
                                  setTradeBooked(response);
                                  setPanel('positions');
                                }}
                                reprice_call_back = {(reprice_request)=>
                                  {
                                    setPricingBond(true);
                                    setBondPricingError(undefined);
                                    fetch(PRICER_URL + '/submit_request/', reprice_request)
                                      .then(res => res.json())
                                      .then(result => setPricingToken(result));
                                  }
                                }/>
                </div>
              </div>
              </div>
            </Col>
          </Row>
        </Container>
        </div>

        <Container>
          <div style={{textAlign:'center'}}>
            <a href="mailto:mike.kipnis@gmail.com">Support</a>
          </div>
        </Container>
    </div>
    </div>
  );
}

export default App;
