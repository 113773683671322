import React from "react";

//export const PRICER_URL = "http://localhost:8000";
export const PRICER_URL = "https://ustreasuries.online";
export const SESSION_URL = "https://ustreasuries.online";
//export const PRICER_URL = "http://localhost:8000";
//export const SESSION_URL = "http://localhost:8000";

//export const URL = "https://options.ustreasuries.online";
//export const PRICER_URL = "https://options.ustreasuries.online";
//export const SESSION_URL = "https://ustreasuries.online";
