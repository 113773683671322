import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react'
import helpers from './helpers';


import { Line } from 'react-chartjs-2';

const { forwardRef, useRef, useImperativeHandle } = React;

const InfAndUnpPanel = React.forwardRef ((props, ref) => {

  const [dates, setDates] = useState([]);
  const [intDates, setIntDates] = useState([]);
  const [cpis, setCPIs] = useState([]);
  const [unrate, setUnrate] = useState([]);
  const [selectedDateIndex, setSelectedDateIndex] = useState();



  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false
  });

  useEffect(() => {

    if ( props.histMarketData !== undefined )
    {
      console.log(props.histMarketData)

      var dates = []
      var cpis = []
      var unrates = []
      var intDates = []

      for (const [date, cpi] of Object.entries(props.histMarketData['CPIAUCSL'])) {
        dates.push(helpers.int_to_date(cpi['business_date']))
        cpis.push(cpi['rate'])
        intDates.push(cpi['business_date'])
      }

      for (const [date, rate] of Object.entries(props.histMarketData['UNRATE'])) {
        unrates.push(rate['rate'])
      }

      //setOptions(options);
      setDates(dates.reverse())
      setIntDates(intDates.reverse())
      setCPIs(cpis.reverse())
      setUnrate(unrates.reverse())
    }

  },[props.histMarketData])

  useEffect(() => {

    if ( cpis !== undefined && intDates!== undefined && selectedDateIndex !== undefined )
    {
        props.on_hist_business_date_change(intDates[selectedDateIndex])
    };
  },[cpis, intDates, selectedDateIndex])

  return (
        <div style={{ height:'30vh', backgroundColor:'#192231', border:'1px solid #424242'}}>
          <Line style={{ height:'30vh', backgroundColor:'#192231', border:'1px solid #424242'}} data={{
            labels: dates,
            datasets: [
              {
                label: "Inflation (CPI)",
                data: cpis,
                borderColor: `rgba(255,200,100)`,
                backgroundColor: `rgba(255,200,100)`,
                yAxisID: 'y',
              },
              {
                type: 'bar',
                label: "Unemployment (%)",
                data: unrate,
                yAxisID: 'y1',
                borderColor:"#3d6a7b",
                backgroundColor: '#3d6a7b',
              }
            ],
          }}
          options={
          {
            onClick: function(evt, element) {
              if(element.length > 0)
              {
                const index = element[0].index;
                setSelectedDateIndex(index)
              }
            },
            elements: {
                   point:{
                       radius: 0.75
                   }
               },
            aspectRatio: 3.5,
            maintainAspectRatio: true,
            scales:{
            y:{
            maintainAspectRatio: false, aspectRatio: 1,
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawBorder: true,
              drawTicks: true
            },
            title: {
              color: 'rgba(255,200,100)',
              display: true,
              text: "Inflation (CPI)",
              font: {
                size: 17
              },
            },
          },
            y1:{
            maintainAspectRatio: false, aspectRatio: 1,
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              color: '#3d6a7b',
              display: true,
              text: "Unemployment (%)",
              font: {
                size: 15
              },
            },

          },
          }}}
         />
        </div>
      );
    });

export default InfAndUnpPanel;
