import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import helpers from './helpers';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';


const { forwardRef, useRef, useImperativeHandle } = React;

const BondPriceChart = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [displayPrices, setDisplayPrices] = useState(false);


  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false,
  });

  useEffect(() => {

            var dates = [];
            var prices = [];
            var yields = [];

            var hist_price_grid = [];

            var bond_price_chart = {}

            if ( props.bondChartData !== undefined && props.bondChartData !== null )
            {
              var hist_price_grid_data = [];

              for (const [key, value] of Object.entries(props.bondChartData))
              {
                //if ( key % 2 == 0 )
                {
                  prices.push(value['price']);
                  yields.push(value['yield'])
                  dates.push(helpers.int_to_date(key));
                }

              }

          /*const chart_data = {
            labels: dates,
            datasets: [
              {
                data : prices,fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
              }]
          };

          const options = {
            plugins: {
              legend: {
                        display: false,
              },
              title: {
                align: 'center',
                position: 'right',
                color:"rgba(75,192,192,1)",
              },
            },*/


            //const config = {
              //type: 'line',
              //data: dates,
              const options = {
                responsive: true,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                elements: {
                       point:{
                           radius: 1.125
                       }
                   },
                stacked: false,
                plugins: {
                  title: {
                    display: true,
                    text: 'Prices/Yields'
                  }
                },
                scales: {
                  y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                  },
                  y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',

                    // grid line settings
                    grid: {
                      drawOnChartArea: false, // only want the grid lines for one axis to show up
                    },
                  },
                }
            //  },
            };

            const chart_data = {
  labels: dates,
  datasets: [
    {
      label: 'Price',
      data: prices, fill:true,
      borderColor: '#65d2f1',
      backgroundColor: "rgb(101, 210, 241, 0.1)",
      yAxisID: 'y',
      borderWidth: 1.5,
      lineTension: 0.4
    },
    {
      label: 'Yield',
      data: yields, fill:true,
      backgroundColor: "rgb(255, 165, 0, 0.1)",
      borderColor: '#FFA500',
      yAxisID: 'y1',
      hidden: true,
      borderWidth: 1.5,
      lineTension: 0.4
    }
  ]
};

    setOptions(options);
    setData(chart_data);
          };

  }, [props.bondChartData]);



  return (
        <div className="ag-theme-balham-dark">
        <div>
          {  /* <Line options={options} data={data} ref={chartRef} style={{position: 'relative', height:'30vh', width:'80vw'}}/> */}
          <Line options={options} data={data} ref={chartRef}
            style={{position: 'relative', height:'30vh', width:'80vw',
                     border:'1px solid #424242', backgroundColor:'#192231'}}/>
        </div>
        </div>
  );
});

export default BondPriceChart;
