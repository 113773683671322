import { useCallback, useMemo, useEffect, useState } from 'react';
import React from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import helpers from './helpers';


const { forwardRef, useRef, useImperativeHandle } = React;

const BondGrid = React.forwardRef ((props, ref) => {

  //const [bondData, setBondData] = useState([]);

  const [pricingData, setPricingData] = useState([]);
  const [marketData, setMarketData] = useState();


  const gridRef = useRef();
  const lastVisibleRef = useRef();
  const clickedIndexRef = useRef();

  useImperativeHandle(ref, () => ({
      update_prices() {
        gridRef.current.api.refreshCells();
        //gridRef.current.api.ensureIndexVisible(visible_row.current);
    }
  }));


const [columnDefs, setColumnDefs] = useState([
   { headerName: 'Name', field: 'name', filter: 'agTextColumnFilter',  pinned: 'left', width: '150px', cellStyle: {'textAlign': 'center'},
      sortable: false,flex: 1},
   { headerName: 'CUSIP', field: 'cusip', flex: 2, filter: 'agTextColumnFilter', cellStyle: {'textAlign': 'center'},   },
   { headerName: 'IssueDate', field: 'issue', sortable: true, cellStyle: {'textAlign': 'center'},  valueFormatter: function (params) {
            return helpers.int_to_date(params.value);
        }, flex: 2 },
   { headerName: 'Maturity', field: 'maturity', sortable: true, cellStyle: {'textAlign': 'center'},  valueFormatter: function (params) {
            return helpers.int_to_date(params.value);
        }, flex: 2 },
   { headerName: 'Tenor', field: 'tenor', cellStyle: {'textAlign': 'center'},  sortable: true, valueFormatter: function (params) {
                 return helpers.int_to_tenor(params.value);
             }, flex: 2 },
   { headerName: 'Price(32)', field: 'price(32)', cellStyle: {'textAlign': 'center'}, valueFormatter: helpers.format_price_32, flex: 2  },
   { headerName: 'Yield', field: 'yield', cellStyle: {'textAlign': 'right'}, flex: 2  },
   { headerName: 'DV01(1MM)', field: 'dv01', cellStyle: {'textAlign': 'right'}, flex: 2  },
   { headerName: 'ModifiedDuration', cellStyle: {'textAlign': 'right'}, field: 'ModifiedDuration', flex: 2  },
   { headerName: 'Convexity(100bps)', cellStyle: {'textAlign': 'right'}, field: 'Convexity', flex: 2  },
   { headerName: 'AI', field: 'AI', cellStyle: {'textAlign': 'right'}, flex: 2  },
   { headerName: 'AI(Days)', field: 'AI(Days)', cellStyle: {'textAlign': 'right'}, flex: 2  },
   { headerName: 'Price(dec)', field: 'price(dec)',cellStyle: {'textAlign': 'right'},  flex: 2  },
   { headerName: 'FullPrice', field: 'DirtyPrice', cellStyle: {'textAlign': 'right'}, flex: 2  },

 ]);

 const gridOptions = {
     rowSelection: 'single',
     onRowClicked: event => {
       clickedIndexRef.current = event.rowIndex;
       props.bondSelectorCallback(event.data);
     },
     onCellClicked: event =>
     {
     },
     onColumnResized: event => {},
     onGridReady: event =>
     {
       /*var defaultSortModel = [
        { colId: 'maturity', sort: 'asc', sortIndex: 0 }
      ];
      gridRef.current.columnApi.applyColumnState({ state: defaultSortModel });*/
    },

    onRowDataChanged: event => {
      var defaultSortModel = [
        { colId: 'maturity', sort: 'asc', sortIndex: 0 }
      ];
      gridRef.current.columnApi.applyColumnState({ state: defaultSortModel });
    },

    onBodyScrollEnd: event =>
    {
        lastVisibleRef.current = gridRef.current.api.getFirstDisplayedRow();
        console.log(event);
    },

     // CALLBACKS
     getRowHeight: (params) => 25
 }

 useEffect(() => {

   var visible_index = undefined;

   if ( gridRef.current.api != undefined )
   {
   gridRef.current.api.ensureIndexVisible(1);


   gridRef.current.api.forEachNode( node=> {

       var text_tenor = helpers.int_to_tenor(parseInt(node.data.tenor));
       if ( visible_index == undefined && text_tenor == props.tenor )
       {
            visible_index = node.rowIndex;
            gridRef.current.api.ensureIndexVisible(visible_index);
       }
     })
   }

 }, [props.tenor]);


 useEffect(() => {

   if ( props.instrumentData !== undefined && props.pricedBonds == undefined && gridRef.current.api !== undefined )
   {
     setPricingData(props.instrumentData);

     var defaultSortModel = [
      { colId: 'maturity', sort: 'asc', sortIndex: 0 }
    ];
    gridRef.current.columnApi.applyColumnState({ state: defaultSortModel });

   } else if ( props.instrumentData !== undefined && props.pricedBonds !== undefined && gridRef.current.api !== undefined  )
   {
       var current_instrument_set = props.instrumentData;
       var updated_instruments = [];

       //for ( const cusip of Object.entries(current_instrument_set) )
       gridRef.current.api.forEachNode(node =>
        {
          if (node.data == null )
            return;

          var cusip = node.data['cusip']

          var value = props.pricedBonds[cusip]

          if ( value == null)
            return

          var instrument_row_out = node.data;

          instrument_row_out['price(32)']=value['CleanPrice']/1000000000.0;
          instrument_row_out['price(dec)']=(value['CleanPrice']/1000000000.0).toFixed(6);
          instrument_row_out['dv01']=value['DV01']/100;
          instrument_row_out['price']=(value['CleanPrice']/1000000000.0).toFixed(6);
          instrument_row_out['yield']=value['Yield']/10000;
          instrument_row_out['DirtyPrice']=(value['DirtyPrice']/1000000000.0).toFixed(6);
          instrument_row_out['AI']=(value['AI']/1000000000.0).toFixed(6);
          instrument_row_out['AI(Days)']=value['AI(Days)'];
          instrument_row_out['ModifiedDuration']=value['modified_duration']/100;
          instrument_row_out['Convexity']=value['convexity']/100;

          instrument_row_out['issue_date']=value['issue_date'];
          instrument_row_out['maturity_date']=value['maturity_date'];
          instrument_row_out['settlement_date']=value['settlement_date'];
          instrument_row_out['settlement_days']=value['settlement_days'];
          instrument_row_out['tenor']=value['tenor'];
          instrument_row_out['next_cashflow_date']=value['next_cashflow_date'];
          instrument_row_out['previous_cash_flow']=value['previous_cash_flow'];

          instrument_row_out['frequency']=value['frequency'];
          instrument_row_out['bdc']=value['bdc'];
          instrument_row_out['day_counter']=value['day_counter'];
          instrument_row_out['gen_rule']=value['gen_rule'];
          instrument_row_out['notional']=value['notional'];
          instrument_row_out['cusip']=cusip

          node.setData(instrument_row_out);
          //updated_instruments.push(instrument_row_out);
        });

        //setPricingData(updated_instruments);
   }

   if ( props.marketData !== undefined )
   {
     setMarketData(props.marketData)
   }

   /*
   if (gridRef.current.columnApi !== undefined )
   {
      var defaultSortModel = [
        { colId: 'maturity', sort: 'asc', sortIndex: 0 }
      ];
      gridRef.current.columnApi.applyColumnState({ state: defaultSortModel });
      //gridRef.current.api.ensureIndexVisible(lastVisibleRef.current);
   }
   */

 }, [props.instrumentData, props.pricedBonds]);

 const onBtnExport = useCallback(() => {

   props.dataExportCallback(gridRef);
 }, []);

  return (
      <div>
       <div className="ag-theme-balham-dark" style={{height:"40vh", width: "100%", display: "inline-block", margin: "auto"}}>
          <div style={{paddingBottom:'5px', color:'#ffeed9'}}>
            Instruments are priced using market data as of
            {
                marketData == undefined ? null :
                ' ' + helpers.int_to_date(marketData.business_date)
            }
          </div>
           <AgGridReact
               rowData={pricingData} ref={gridRef} columnDefs={columnDefs} gridOptions={gridOptions}>
           </AgGridReact>
           <div style={{display: 'flex', textAlign:'center', paddingTop:'5px'}}>
           <div style={{paddingTop:'5px', color:'#ffeed9'}}>
              (Select a bond to populate the analytics panel below)
           </div>
           <div style={{ marginLeft: 'auto'}}>
            <button onClick={onBtnExport}>Download CSV export file</button>
          </div>
          </div>
       </div>
      </div>
   );
});

export default BondGrid;
