import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import helpers from './helpers';
import BondPriceChart from './BondPriceChart'

import {AgGridColumn, AgGridReact} from 'ag-grid-react';


const { forwardRef, useRef, useImperativeHandle } = React;

const HistPrices = React.forwardRef ((props, ref) => {

  const histPricesGridRef = React.useRef();

  const [histPrices, setHistPrices] = useState();

  const histPricesGridColumnDefs = [
    { field: 'business_date', headerName: 'Date', sortable: true,
      flex: 2,cellStyle: {'textAlign': 'center', padding:'3px'},
      valueFormatter: function (params) {
        return helpers.int_to_date(params.data.business_date);
        }, },
    { headerName: 'Price(32)', field: 'price(32)', valueFormatter: helpers.format_price_32, flex: 2  },
    { headerName: 'Yield', field: 'yield', flex: 2  },
    { headerName: 'DV01(1MM)', field: 'dv01', flex: 2  },
    { headerName: 'ModifiedDuration', field: 'ModifiedDuration', flex: 2  },
    { headerName: 'Convexity(100bps)', field: 'Convexity', flex: 2  },
    { headerName: 'AI', field: 'AI', flex: 2  },
    { headerName: 'AI(Days)', field: 'AI(Days)', flex: 2  },
    { headerName: 'Price(dec)', field: 'price(dec)', flex: 2  },
    { headerName: 'FullPrice', field: 'DirtyPrice', flex: 2  },
  ];

  const histPricesGridOptions = {

    getRowHeight: (params) => 25,
    onGridReady: event => {

      var defaultSortModel = [
        { colId: 'business_date', sort: 'desc', sortIndex: 0 }
      ];

      histPricesGridRef.current.columnApi.applyColumnState({ state: defaultSortModel });
    },
    //getRowHeight: (params) => 25
}

  useEffect(() => {

            if ( props.bondChartData !== undefined && props.bondChartData !== null )
            {
              var hist_price_grid_data = [];

              for (const [key, value] of Object.entries(props.bondChartData))
              {
                var hist_price_data = {};
                hist_price_data['business_date'] = key;
                hist_price_data['price(32)']=value['CleanPrice']/1000000000;
                hist_price_data['price(dec)']=value['CleanPrice']/1000000000;
                hist_price_data['dv01']=value['DV01']/100;
                hist_price_data['price']=value['CleanPrice']/1000000000;
                hist_price_data['yield']=value['Yield']/10000;
                hist_price_data['DirtyPrice']=value['DirtyPrice']/1000000000;
                hist_price_data['AI']=value['AI']/1000000000;;
                hist_price_data['AI(Days)']=value['AI(Days)'];
                hist_price_data['ModifiedDuration']=value['modified_duration']/100;
                hist_price_data['Convexity']=value['convexity']/100;

                hist_price_grid_data.push(hist_price_data);
              }

              setHistPrices(hist_price_grid_data);
            }

  }, [props.bondChartData]);



  return (
        <div>
          <BondPriceChart bondChartData={props.bondChartData}/>
        <div>
        </div>
        <div className="ag-theme-balham-dark" style={{height:"30vh", width: "100%", display: "inline-block", margin: "auto", paddingTop:"5px"}}>
        <div style={{marginBottom:'5px'}}>
            Historical Prices and Risk
        </div>
        <AgGridReact rowData={histPrices}
                    columnDefs={histPricesGridColumnDefs}
                    gridOptions={histPricesGridOptions}
                    ref={histPricesGridRef}>
        </AgGridReact>
        </div>
        </div>
  );
});

export default HistPrices;
