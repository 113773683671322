import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import helpers from './helpers';
import BondPriceChart from './BondPriceChart'
import SpreadsPanel from './SpreadsPanel'
import InfAndUnpPanel from './InfAndUnpPanel'


import {AgGridColumn, AgGridReact} from 'ag-grid-react';


const { forwardRef, useRef, useImperativeHandle } = React;

const ChartedKeyRates = React.forwardRef ((props, ref) => {

  const chartRef = useRef();
  const spreadsRef = useRef();
  const datesAndRatesRef = useRef();

  const [curveDatesYields, setCurveDatesYields] = useState();


  const [data, setData] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false
  });

  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false,
  });

  const handleChartClick = (index) =>
  {
    var curve = datesAndRatesRef.current[index];
    props.on_hist_business_date_change(curve['business_date'])
  }

  useEffect(() => {

            var dates = [];
            var int_dates = [];
            var yields = {};
            var all_yields = {};

            var hist_price_grid = [];
            var bond_price_chart = {}

            var colors = {
              //'1M': '#99b5bd',
              '3M': '#D3D3D3',
              //'6M': '#8cbcca',
              //'1Y': '#86c0d0',
              '2Y': '#3c7e90',
              //'3Y': '#758b42',
              '5Y': '#903C7E',
              //'7Y': '#D0F1FA',
              '10Y': '#65d2f1',
              //'20Y': '#FFD580',
              '30Y': '#FFA500'
            };

            var tenors = ['1M', '3M', '6M', '1Y', '2Y', '3Y', '5Y', '7Y', '10Y', '20Y', '30Y'];

            if ( props.histMarketData !== undefined )
            {
              var hist_price_grid_data = [];

              datesAndRatesRef.current = props.histMarketData['CURVE'].reverse()

              for (const [key, value] of Object.entries(props.histMarketData['CURVE']))
              {
                dates.push(helpers.int_to_date(value['business_date']))
                int_dates.push(value['business_date'])
                //delete value['business_date']


                Object.entries(colors).map(entry => {

                  var yield_value = undefined

                  if (value.hasOwnProperty(entry[0]))
                  {
                    yield_value = value[entry[0]]
                  }

                  if ( !yields.hasOwnProperty(entry[0]) )
                    yields[entry[0]] = []

                  yields[entry[0]].push(yield_value);

                });


              }

              dates.reverse()

              setCurveDatesYields({'dates':dates.reverse(), 'yields': props.histMarketData['CURVE'], 'int_dates':int_dates})

            }

            var data_sets = []

            Object.entries(colors).map(entry => {

              if ( yields.hasOwnProperty(entry[0]) ) {
                var data_set = {
                  label: entry[0],
                  data : yields[entry[0]],
                  fill: false,
                  backgroundColor: entry[1],
                  borderColor: entry[1],
                  pointStyle: false,
                  borderWidth: 1,
                  lineTension: 0.4,
                  maintainAspectRatio: false
              }
              data_sets.push(data_set)
            }

            })

          const chart_data = {
            labels: dates,
            datasets: data_sets,
            maintainAspectRatio: false
          };

          const options = {

            onClick: function(evt, element) {
  if(element.length > 0)
  {
    console.log('Clicked!!!' + curveDatesYields)
    handleChartClick(element[0].index)


    /*const index = element[0].index;
    var cpi = props.histMarketData.CPIAUCSL[index]
    //var curve = props.histMarketData.CURVE[index]

    var market_data = {}
    market_data['business_date'] = cpi['business_date']

    var curve = {}
    for (const [curve_index, rate] of Object.entries(props.histMarketData.CURVE[index])) {
      curve[curve_index] = rate/100.0;
    }

    market_data['market_data'] = curve


    props.on_market_data_change(market_data)*/
  }
},


            maintainAspectRatio: false,
            plugins: {
              legend: {
                        display: true,
              },
              maintainAspectRatio: false,
              title: {
                align: 'center',
                position: 'right',
                color:"rgba(75,192,192,1)",
              },
            },
            elements: {
                   point:{
                       radius: 1.125
                   }
               },
          };

          setOptions(options);
          setData(chart_data);

  }, [props.histMarketData]);


  return (
    <div style={{paddingLeft:'10px'}}>
      <div  style={{paddingLeft:'10px'}}>
        Benchmarks
      </div>
    <div  style={{
    height:'30vh', backgroundColor:'#192231', border:'1px solid #424242'}}>
      <Line options={options} data={data} ref={chartRef} style={{
      height:'30vh', backgroundColor:'#192231'}}/>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', width:'100%' }}>
        <SpreadsPanel curveDatesYields={curveDatesYields}
          on_hist_business_date_change={(hist_business_date) => { props.on_hist_business_date_change(hist_business_date)}}/>
    </div>
    </div>
  );
});

export default ChartedKeyRates;
