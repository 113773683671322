import React from 'react'
import { useEffect, useState } from 'react';

import BondTermStructurePanel from './BondTermStructurePanel'
import CashflowsPanel from "./CashflowsPanel"
import HistPrices from "./HistPrices"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import helpers from './helpers'

//const url = "http://localhost:7921";

//const url = "http://localhost:8000";

const CalculatorPanel = React.forwardRef ((props, ref) => {

  const [bond, setBond] = useState();

  /*const [marketData, setMarketData] = useState();
  const [valuationDate, setValuationDate] = useState();
  const [bondTemplate, setBondTemplate] = useState();*/

  const [pricingToken, setPricingToken] = useState();
  const [pricingResults, setPricingResults] = useState();

  const handleSetup = ( bond, elementName, new_value ) =>
  {
      bond[elementName] = new_value;


      console.log( "Element : " + elementName + " : " + new_value );

      reprice(bond);

      /*
      if ( props.bond !== undefined)
      {
        var curve = {};
        var price_request_out = {};

        bond[elementName] = new_value;

        curve["curve_points"] = props.marketData["market_data"];
        curve["bond_template"] = props.bondTemplate;

        price_request_out["business_date"] = new Date(props.valuationDate).toISOString().slice(0, 10);
        price_request_out["portal"] = "BOND_PORTAL";
        price_request_out["operation"] = "PRICE_TO_YIELD";
        price_request_out["curve"] = curve;
        price_request_out["curve"]["curve_date"] = "";
        price_request_out["curve"]["curve_out"] = [];
        price_request_out["bond_template"] = props.bondTemplate;
        price_request_out["bond"] = JSON.parse(JSON.stringify(bond));
        price_request_out["bond"]["ust_bond_schedule"] = JSON.parse(JSON.stringify(props.bondTemplate.ust_bond_schedule));
        price_request_out["bond"]["ust_fixed_rate_bond"] = JSON.parse(JSON.stringify(props.bondTemplate.ust_fixed_rate_bond));

        price_request_out["bond"]["ust_bond_schedule"]["EffectiveDate"] = bond.issue_date;
        price_request_out["bond"]["ust_bond_schedule"]["TerminationDate"] = bond.maturity_date;
        price_request_out["bond"]["ust_fixed_rate_bond"]["IssueDate"] = bond.issue_date;
        price_request_out["bond"]["ust_fixed_rate_bond"]["Coupons"] = [bond.coupon/100.0,];

        price_request_out["bond"]["ust_bond_schedule"]["ObjectId"] = "schedule_" + bond.cusip;
        price_request_out["bond"]["ust_fixed_rate_bond"]["ObjectId"] = "ust_fixed_rate_bond_" + bond.cusip;
        price_request_out["bond"]["ust_fixed_rate_bond"]["ScheduleID"] = price_request_out["bond"]["ust_bond_schedule"]["ObjectId"];

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(price_request_out)
        };

          props.reprice_call_back(requestOptions);
      }*/
  };

  const reprice = ( bond ) =>
  {
    var curve = {};
    var price_request_out = {};

    //bond[elementName] = new_value;

    curve["curve_points"] = props.marketData["market_data"];
    curve["bond_template"] = props.bondTemplate;

    var operation_value = parseFloat(bond[bond["operation"]])
    var operation_type = bond["operation"]
    bond[operation_type] = operation_value

    price_request_out["business_date"] = new Date(props.valuationDate).toISOString().slice(0, 10);
    price_request_out["portal"] = "BOND_PORTAL";
    price_request_out["operation"] = bond["operation"]
    //price_request_out[operation_type]=parseFloat(operation_value)
    price_request_out["curve"] = curve;
    price_request_out["curve"]["curve_date"] = "";
    price_request_out["curve"]["curve_out"] = [];
    price_request_out["bond_template"] = props.bondTemplate;
    price_request_out["bond"] = JSON.parse(JSON.stringify(bond));
    price_request_out["bond"]["ust_bond_schedule"] = JSON.parse(JSON.stringify(props.bondTemplate.ust_bond_schedule));
    price_request_out["bond"]["ust_fixed_rate_bond"] = JSON.parse(JSON.stringify(props.bondTemplate.ust_fixed_rate_bond));

    price_request_out["bond"]["ust_bond_schedule"]["EffectiveDate"] = bond.issue_date;
    price_request_out["bond"]["ust_bond_schedule"]["TerminationDate"] = bond.maturity_date;
    price_request_out["bond"]["ust_fixed_rate_bond"]["IssueDate"] = bond.issue_date;
    price_request_out["bond"]["ust_fixed_rate_bond"]["Coupons"] = [bond.coupon/100.0,];
    price_request_out["bond"]["ust_fixed_rate_bond"]["FaceAmount"] = bond.notional;

    price_request_out["bond"]["ust_bond_schedule"]["ObjectId"] = "schedule_" + bond.cusip;
    price_request_out["bond"]["ust_fixed_rate_bond"]["ObjectId"] = "ust_fixed_rate_bond_" + bond.cusip;
    price_request_out["bond"]["ust_fixed_rate_bond"]["ScheduleID"] = price_request_out["bond"]["ust_bond_schedule"]["ObjectId"];

    price_request_out["bond"]["ust_fixed_rate_bond"]["SettlementDays"] = parseInt(bond['settlement_days'])

    price_request_out["bond"]["ust_fixed_rate_bond"]["DayCounter"] = bond['day_counter'];
    price_request_out["bond"]["ust_bond_schedule"]["Tenor"] = bond['frequency'];

    price_request_out["bond"]["ust_bond_schedule"]["TermDateConv"] = bond['bdc'];
    price_request_out["bond"]["ust_bond_schedule"]["Convention"] = bond['bdc'];
    price_request_out["bond"]["ust_bond_schedule"]["GenRule"] = bond['gen_rule'];

    price_request_out["bond"]["ust_fixed_rate_bond"]["PaymentBDC"] = bond['bdc'];


    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(price_request_out)
    };

    /*fetch(url + '/submit_request/', requestOptions)
      .then(res => res.json())
      .then(result => setPricingToken(result));
      */

      props.reprice_call_back(requestOptions);
  }

  useEffect(() => {

    if ( bond !== undefined && bond.cashflows == undefined )
    {
        reprice(bond);
    }

  },  [bond]);


  useEffect(() => {

    if ( props.bond !== undefined )
    {
        setBond( props.bond );
    }

  },  [props.bond]);


  return (
      <div>
        <Container fluid>
          <Row>
            <Col style={{width:'50%', marginTop:'10px'}}>
              <Row>
              <BondTermStructurePanel name="The Bond"
                bond={bond}
                fedStaticData={props.fedStaticData}
                bondPricingError={props.bondPricingError}
                userCredentials={props.userCredentials}
                positions={props.positions}
                handleSetup={handleSetup}
                tradeBookCallback={(response)=>{ props.tradeBookCallback(response); }}/>
               </Row>
            </Col>
            <Col style={{width:'50%', marginTop:'10px'}} id="support">
              <HistPrices bondChartData={props.bondChartData}/>
            </Col>
          </Row>
        </Container>
     </div>
    )
 });

 export default CalculatorPanel;
